<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">

      <div class="d-flex align-items-center mb-1 mr-1">

        <span class="title mr-1">
          Date a Date:
        </span>
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker"
          placeholder="Date a Date"
          @on-change="getAllHistory"
        />
      </div>

      <b-form-group class="print">
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group class="print">
        <b-button
          variant="gradient-primary"
          class="ml-1 d-inline-block"
          @click="imprimer"
        >
          <feather-icon icon="PrinterIcon" />
          <span class="ml-1">Imprimer</span>
        </b-button>
      </b-form-group>
      <download-excel
        :data="histories"
        class="ml-1"
      >
        <b-button
          variant="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          Excel
        </b-button>
      </download-excel>

    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="histories"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'quantity'"
          class="text-nowrap"
        >
          <b-avatar variant="success">
            <feather-icon icon="TrendingUpIcon" />
          </b-avatar>
          <span class="text-nowrap ml-5">{{ props.row.quantity }}</span>
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <div class="custom-search d-flex justify-content-end mt-1">
      <h3 style="color: black"> Nombre de boite  : {{ this.total }} </h3>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,
  BSpinner, BAvatar, BButton,

} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import qs from 'qs'
import JsonExcel from 'vue-json-excel'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BAvatar,
    flatPickr,
    BButton,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      rangePicker: ['', ''],
      columns: [
        {
          label: 'Référence',
          field: 'product_reference',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher reference',
          },
        },
        {
          label: 'Produit',
          field: 'product_name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher product',
          },
        },

        {
          label: 'Vente enLigne',
          field: 'quantity_en_ligne',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher quantité',
          },
        },
        {
          label: 'Vente direct',
          field: 'quantity_direct',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher quantité',
          },
        },
        {
          label: 'Vente gros',
          field: 'quantity_gros',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher quantité',
          },
        },
        {
          label: 'total',
          field: 'quantity_total',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher quantité',
          },
        },

      ],
      histories: [],
      total: 0,
      searchTerm: '',
      date: null,
    }
  },
  computed: {

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  methods: {
    imprimer() {
      window.print()
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },
    // get client

    async getAllHistory() {
      this.load = 'true'

      const ranges = this.rangePicker.split(' ')

      let obj
      if (ranges[2] === undefined) {
        obj = { dateDeb: ranges[0], dateFin: ranges[0] }
      } else {
        obj = { dateDeb: ranges[0], dateFin: ranges[2] }
      }
      await axios.post('/histories/date-to-date-sfax/', qs.stringify(obj)).then(response => {
        this.histories = response.data.products
        this.total = response.data.total_quantity
        this.load = 'false'
      })
    },
    // delete client

    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@media print {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .print{
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    font-size: 22px; /* Adjust the value as needed */
    font-weight: bold;
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;

  }
}
</style>
